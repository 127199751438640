import React, { useEffect, useState } from "react";
import { listPropertiesV2 } from "../../api/property/property";
import { listConfigure } from "../../api/configure/configure";
import { showToast } from "../../components/Toast";
import { showLoader } from "../../components/Loader";
import Pagination from "@mui/material/Pagination";
import BlogItem from "../../../src/components/BlogItem";
import _ from "lodash";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import PropertyFilterSelector from "./components/PropertyFilterSelector";

const Properties = (props) => {
  const pageSize = 9;

  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [configures, setConfigures] = useState([]);
  const price = [
    { value: 0, label: "Please Select" },
    { value: 1, label: "Below RM 1000" },
    { value: 2, label: "RM 1001-RM 1500" },
    { value: 3, label: "RM 1501-RM 2000" },
    { value: 4, label: "RM 2001-RM 2500" },
    { value: 5, label: "RM 2500 and above" },
  ];

  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedPriceRange, setSelectedPriceRange] = useState();
  const [searchText, setSearchText] = useState();
  const [_searchText, _setSearchText] = useState();

  const [isFilterVisible, setButtonVisible] = useState(false);

  const buildFilter = () => {
    let filterText = "";
    if (selectedCategory) {
      filterText = `category=${selectedCategory};`;
    }
    if (selectedPriceRange) {
      filterText = `${filterText}price=${selectedPriceRange};`;
    }
    if (searchText) {
      filterText = `${filterText}search=${searchText}`;
    }
    return filterText;
  };

  const onGetProperties = async () => {
    try {
      setIsLoading(true);
      const filterText = buildFilter();
      const { data } = await listPropertiesV2({
        page: currentPage,
        limit: pageSize,
        filter: filterText,
      });
      const { Result, pagination } = data;
      const { current_page, total_page } = pagination;
      setTotalPage(total_page);
      setCurrentPage(current_page);

      setProperties(Result);
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectPrice = async (e) => {
    setSelectedPriceRange(e.value);
  };

  const onSelectCategory = (e) => {
    setSelectedCategory(e.value);
  };

  const onGetCategories = async () => {
    try {
      setIsCategoriesLoading(true);
      const { data: configuresData } = await listConfigure("PropertyName");
      const isSuccess = configuresData?.IsSuccess || false;
      if (!isSuccess) {
        throw new Error("Retrieve Categories Failed!");
      }
      const options = configuresData.Result.map((item) => {
        return { value: item.Value, label: item.Value };
      });
      setConfigures(options);
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setIsCategoriesLoading(false);
    }
  };

  useEffect(() => {
    onGetCategories();
  }, []);

  useEffect(() => {
    onGetProperties();
  }, [selectedCategory, selectedPriceRange, searchText, currentPage]);

  return (
    <>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="banner-area text-center pt-4 pb-4">
              <div className="search-area">
                <input
                  type="text"
                  className="inp-search"
                  placeholder="Search Property..."
                  onChange={(e) => {
                    _setSearchText(e.target.value);
                  }}
                />
                <button
                  className="btn-search m-2"
                  onClick={() => {
                    setSearchText(_searchText);
                    _setSearchText("");
                  }}
                >
                  <span>Search </span>
                  <i className="bi bi-search"></i>
                </button>
              </div>

              <div className="page-content">
                {isFilterVisible && (
                  <div>
                    <h4
                      style={{
                        borderLeft: "6px solid rgb(0, 200, 158)",
                        padding: "12px",
                        marginBottom: "30px",
                      }}
                    >
                      WHERE WOULD YOU RATHER LIVE?
                    </h4>
                    {!isCategoriesLoading && (
                      <div className="row">
                        <PropertyFilterSelector
                          value={selectedPriceRange}
                          onSelect={onSelectPrice}
                          options={price}
                          label={"Price"}
                        />
                        <PropertyFilterSelector
                          value={selectedCategory}
                          onSelect={onSelectCategory}
                          options={configures}
                          label={"Category"}
                        />
                      </div>
                    )}
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "2%",
                  }}
                >
                  {isFilterVisible && (
                    <button
                      onClick={() => {
                        props.history.replace(`/properties`);
                        window.location.reload();
                      }}
                      style={{
                        color: "#253156",
                        backgroundColor: "white",
                        borderRadius: "0.2rem",
                        border: 0,
                        padding: "12px 25px 12px 25px",
                        minWidth: "130px",
                        marginRight: 30,
                        boxShadow: " rgba(0, 0, 0, 0.5) 1px 1px 3px 0px",
                      }}
                    >
                      Reset
                    </button>
                  )}
                  <button
                    className="btn-search"
                    onClick={() => {
                      setButtonVisible(!isFilterVisible);
                    }}
                  >
                    {isFilterVisible ? "Close" : "Filter"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <h4
            style={{
              borderLeft: "6px solid rgb(0, 200, 158)",
              padding: "12px",
              marginBottom: "30px",
            }}
          >
            LATEST PROPERTY
          </h4>
          <div className="row">
            {isLoading ? (
              showLoader(isLoading)
            ) : (
              <>
                {properties.length > 0 ? (
                  properties.map((property, index) => {
                    return (
                      <BlogItem
                        key={index}
                        propertyId={property.PropertyId}
                        price={property.Price}
                        propertyName={property.PropertyName}
                        city={property.City}
                        address={property.Address}
                        sqft={property.Sqft}
                        carParks={property.Carpark}
                        furnishing={property.Furnishing}
                        imgPath={property.Image}
                        statusCode={property.StatusCode}
                        name={property.Name}
                      />
                    );
                  })
                ) : (
                  <h1>No data to be shown!</h1>
                )}
              </>
            )}
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", margin: 50 }}
          >
            <Pagination
              count={totalPage}
              color="primary"
              size="large"
              onChange={(e, page) => {
                setCurrentPage(page);
              }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Properties;
