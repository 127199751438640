import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Store from "@material-ui/icons/Store";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardFooter from "../../components/Card/CardFooter.js";
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Cookies from "universal-cookie";
import {
  getContract,
  downloadContractTemplate,
} from "../../api/contract/contract";
import { getTransactions } from "../../api/transactions/transactions";
import moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import { Stack } from "@mui/material";
import Money from "@material-ui/icons/AttachMoney";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";
import { useHistory } from "react-router-dom";

const MyContract = () => {
  const history = useHistory();
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const cookies = new Cookies();
  const [userId, setUserId] = useState();
  const [contract, setContract] = useState();
  const [trans, setTrans] = useState([]);

  useEffect(() => {
    const id = cookies.get("user_id");
    setUserId(id);
    if (!contract) {
      onGetContract();
    }
    getTransactions().then((x) => {
      setTrans(x.data.Result);
    });
  }, [userId]);

  const onGetContract = async () => {
    try {
      const { data } = await getContract();
      if (data.Result.length > 0) {
        data.Result.map((x) => {
          if (x.ClientId === userId) {
            setContract(x);
          }
          return null;
        });
      }
    } catch (error) {
      console.log("Contract list is empty.");
    }
  };

  return (
    <React.Fragment>
      <Header />
      <div className="container">
        <GridContainer>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>My Contract</p>
              <h3 className={classes.cardTitle}>
                {moment(contract?.EffectiveStartDate).format("L")}{" "}
                <small>to</small>{" "}
                {moment(contract?.EffectiveEndDate).format("L")}
              </h3>
              <div
                className="container"
                style={{ color: "black", textAlign: "center" }}
              >
                <p>{contract?.Property?.Name}</p>
                <p>Condo Name: {contract?.Property?.PropertyName}</p>
                <p>Monthly Rental: RM {contract?.Property?.Price}</p>
                <p>Contact Person: {contract?.Admin?.Name}</p>
              </div>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <IconButton
                    onClick={() => {
                      window.open(
                        downloadContractTemplate(contract?.ContractId)
                      );
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                  {/* <IconButton>
                                        <InfoIcon />
                                    </IconButton> */}
                </Stack>
              </div>
              <div className={classes.stats}>
                {moment(contract?.CreatedDate).format("LLL")}
              </div>
            </CardFooter>
          </Card>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Money />
              </CardIcon>
              <p className={classes.cardCategory}>My Recent Transaction</p>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Amount</TableCell>
                      <TableCell align="right">Submission Date</TableCell>
                      <TableCell align="right">Is Acknowledged</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {trans &&
                      trans.map((row, index) => {
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="right">RM {row.Amount}</TableCell>
                            <TableCell align="right">
                              {moment(row.CreatedDate).format("LLL")}
                            </TableCell>
                            <TableCell align="right">
                              {row.IsAck ? "Yes" : "No"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <IconButton
                    onClick={() => {
                      history.replace("/transactions/upload");
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Stack>
              </div>
            </CardFooter>
          </Card>
        </GridContainer>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default MyContract;
