import React, { useEffect, useState } from "react";

const TeamItem = ({ team }) => {
    const [teamObj, setTeam] = useState({});

    useEffect(() => {
        setTeam(team);
    }, [team]);

    return (
        <div className="col-lg-4">
            <div className="team">
                <div className="team-img">
                    <img src={teamObj.imgUrl} alt={teamObj.name} />
                </div>
                <div className="team-info">
                    <h5 className="team-name">{teamObj.name}</h5>
                    <h6 className="team-position">{teamObj.role}</h6>
                    <div className="social-links">
                        <div className="social-item"><i className="fab fa-facebook"></i></div>
                        <div className="social-item"><i className="fab fa-whatsapp"></i></div>
                        <div className="social-item"><i className="fab fa-instagram"></i></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamItem