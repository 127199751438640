import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { retrieveAccessToken } from "../api/authUtil";
import Cookies from "universal-cookie";
import { listConfigure } from "../api/configure/configure";

const accessTokenLabel = process.env.REACT_APP_ACCESS_TOKEN_LABEL;
const userIdLabel = process.env.REACT_APP_USER_ID_LABEL;
const userTypeLabel = process.env.REACT_APP_USER_TYPE_LABEL;

const cookies = new Cookies();

const Header = () => {
  const [configures, setConfigures] = useState([]);
  const history = useHistory();
  const accessToken = retrieveAccessToken();
  const onLogOut = () => {
    cookies.remove(userIdLabel);
    cookies.remove(userTypeLabel);
    cookies.remove(accessTokenLabel);
    history.push("/");
  };

  useEffect(() => {
    let isMounted = true;
    const onGetConfig = async () => {
      listConfigure("PropertyName").then((x) => {
        if (isMounted) {
          setConfigures(
            x.data.Result.map((y) => {
              return y.Value;
            })
          );
        }
      });
    };

    onGetConfig();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="header">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <div className="d-flex align-items-center">
                <img
                  src="/img/Logo/urban-room-logo1.jpg"
                  alt="Urban-Room-Logo1.jpg"
                />
              </div>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/properties">
                    Properties <i className="fas fa-chevron-down"></i>
                  </Link>
                  <ul className="sub-ul">
                    {configures?.map((elem, index) => {
                      return (
                        <li key={index}>
                          <Link
                            to={`/properties?category=${elem}`}
                            query={{ category: elem }}
                          >
                            {elem}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/book">
                    Set Appointment
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="#">
                    Our Company <i className="fas fa-chevron-down"></i>
                  </Link>
                  <ul className="sub-ul">
                    <li>
                      <Link to="/aboutus">About Us</Link>
                    </li>
                    <li>
                      <Link to="/team">Our Team</Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    Contact
                  </Link>
                </li>
                {accessToken ? (
                  <li className="nav-item">
                    <Link className="nav-link" to="#">
                      <i className="fas fa-user-circle fa-2x"></i>
                    </Link>
                    <ul className="sub-ul">
                      <li>
                        <Link to="/contract">My Contract</Link>
                      </li>
                      <li>
                        <Link to="/feedback">Feedback</Link>
                      </li>
                      <li>
                        <Link to="/transactions/upload">Transaction</Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          onClick={() => {
                            onLogOut();
                          }}
                        >
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <li className="nav-item">
                    <Button
                      style={styleSheet.button}
                      onClick={() => {
                        history.push("/login");
                      }}
                    >
                      Login
                    </Button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

const styleSheet = {
  button: {
    height: 35,
    width: 100,
    alignSelf: "center",
    marginTop: "1%",
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "sans-serif",
    color: "white",
    backgroundColor: "#253156",
  },
};

export default Header;
