import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ImageBanner from "../../components/ImageBanner";
import TeamItem from "../../components/TeamItem";

const Team = () => {
  const team = [
    {
      name: "Albert Ting",
      imgUrl: "/img/Company/founder.jpg",
      role: "Founder",
      facebook: "#",
      insta: "#",
      whatsapp: "#",
    },
    {
      name: "Jane Lee",
      imgUrl: "/img/Company/co-founder1.jpg",
      role: "Co-Founder 1",
      facebook: "#",
      insta: "#",
      whatsapp: "#",
    },
    {
      name: "Vincent Yong",
      imgUrl: "/img/Company/co-founder2.jpg",
      role: "Co-Founder 2",
      facebook: "#",
      insta: "#",
      whatsapp: "#",
    },
    {
      name: "Brandon Ting",
      imgUrl: "/img/Company/manager-1.jpg",
      role: "Business Development Manager",
      facebook: "#",
      insta: "#",
      whatsapp: "#",
    },
    {
      name: "Ming Kuang",
      imgUrl: "/img/Company/manager-2.jpg",
      role: "Business Development Manager",
      facebook: "#",
      insta: "#",
      whatsapp: "#",
    },
    {
      name: "Simon Chin",
      imgUrl: "/img/Company/admin-1.jpg",
      role: "Administrator",
      facebook: "#",
      insta: "#",
      whatsapp: "#",
    },
    {
      name: "Khan Ren",
      imgUrl: "/img/Company/manager-3.jpg",
      role: "Social Media Manager",
      facebook: "#",
      insta: "#",
      whatsapp: "#",
    },
    {
      name: "Duncan Ting",
      imgUrl: "/img/Company/advisor-1.jpg",
      role: "Advisor",
      facebook: "#",
      insta: "#",
      whatsapp: "#",
    },
    {
      name: "Hao Yang",
      imgUrl: "/img/Company/advisor-2.jpg",
      role: "Advisor",
      facebook: "#",
      insta: "#",
      whatsapp: "#",
    },
    {
      name: "Dennis Hii",
      imgUrl: "/img/Company/advisor-3.jpg",
      role: "Advisor",
      facebook: "#",
      insta: "#",
      whatsapp: "#",
    },
  ];

  return (
    <React.Fragment>
      <Header />
      <ImageBanner imgUrl={"/img/Company/team.jpg"} text={"Our Team"} />
      <section className="section-teams">
        <div className="container">
          <div className="row">
            {team.map((elem, index) => {
              return <TeamItem key={index} team={elem} />;
            })}
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default Team;
