import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { addFeedback } from "../../api/feedback/feedback";
import IconButton from "@mui/material/IconButton";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { showToast } from "../../components/Toast";
import Cookies from "universal-cookie";
import validator from "validator";

const userIdLabel = process.env.REACT_APP_USER_ID_LABEL;

const BookForm = ({ currentFlag, setCurrentFlag }) => {
  const cookies = new Cookies();
  const [form, setForm] = useState({
    email: null,
    contactNumber: null,
    firstName: null,
    lastName: null,
  });

  const [userId, setUserId] = useState();

  const submitForm = async () => {
    const { email, contactNumber, firstName, lastName } = form;
    if (email && contactNumber && firstName && lastName && currentFlag) {
      if (!validator.isEmail(email)) {
        showToast("error", "Email is not valid.");
        return;
      }

      if (
        !validator.isMobilePhone(contactNumber, "ms-MY", { strictMode: true })
      ) {
        showToast("error", "Contact Number is not valid.");
        return;
      }

      const feedback = {
        Category: currentFlag,
        Title: "New Appointment from " + form.firstName + " " + form.lastName,
        Description:
          "Email address is " +
          form.email +
          " and contact number is " +
          form.contactNumber,
        CreatedBy: userId ? userId : "Anonymous Client",
        Image: "",
        Image2: "",
      };

      const res = await addFeedback(feedback);
      if (res) {
        const isSuccess = res["data"]?.IsSuccess;
        if (isSuccess) {
          showToast("success", "Appointment Created");
          setCurrentFlag("");
          window.location.reload();
        } else {
          showToast("error", "Failed to create appointment");
        }
      }
    } else {
      showToast("error", "Some fields are empty");
    }
  };

  useEffect(() => {
    const id = cookies.get(userIdLabel);
    setUserId(id);
  }, [userId]);

  return (
    <section className="section-teams">
      <hr />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          aria-disabled
          disableFocusRipple
          disableRipple
          disableTouchRipple
          onClick={() => {
            setCurrentFlag("");
          }}
        >
          <CancelPresentationIcon fontSize="large" />
        </IconButton>
      </Box>
      <div className="container">
        <Container component="main" maxWidth="sl" sx={{ mb: 4 }}>
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 4, md: 6 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              New Appointment
            </Typography>
            <Grid container spacing={3} mt={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="firstName"
                  name="firstName"
                  label="First name"
                  fullWidth
                  autoComplete="given-name"
                  variant="standard"
                  onChange={(event) => {
                    setForm({ ...form, firstName: event.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="lastName"
                  name="lastName"
                  label="Last name"
                  fullWidth
                  autoComplete="family-name"
                  variant="standard"
                  onChange={(event) => {
                    setForm({ ...form, lastName: event.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="email"
                  name="email"
                  label="Email"
                  fullWidth
                  autoComplete="email"
                  variant="standard"
                  onChange={(event) => {
                    setForm({ ...form, email: event.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} mb={6}>
                <TextField
                  id="contact-number"
                  name="contactNumber"
                  label="Contact Number (+60)"
                  fullWidth
                  autoComplete="contact-number"
                  variant="standard"
                  placeholder="+601234567890"
                  onChange={(event) => {
                    setForm({ ...form, contactNumber: event.target.value });
                  }}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" onClick={submitForm}>
                Submit
              </Button>
            </Box>
          </Paper>
        </Container>
      </div>
    </section>
  );
};

export default BookForm;
