import axios from "axios";
import { retrieveAccessToken } from "../authUtil";

export const addConfigure = async (details) => {
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/info/addcategory`,
    data: details,
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`,
    },
  };
  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const deleteConfigure = async (id) => {
  const config = {
    method: "DELETE",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/info/deletecategory?id=${id}`,
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`,
    },
  };
  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const listConfigure = async (category) => {
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/info/getvaluesbycategory?category=${category.replace(" ", "")}`,
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`,
    },
  };
  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
