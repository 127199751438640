import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const FlatItem = ({ property }) => {
  const [propObj, setProperty] = useState([]);

  useEffect(() => {
    setProperty(property);
  }, [property]);

  return (
    <div className="text-center col-lg-4 col-12 col-md-6 ">
      <div className="item">
        <div className="item-image">
          <img
            className="img-fluid"
            src={propObj.Image?.split(",")[0]}
            alt={propObj.Name}
          />
        </div>
        <div className="item-description">
          <div className="d-flex justify-content-between mb-5">
            <span className="item-title">{propObj.Name}</span>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <span className="item-money">
              <i className="fas fa-check-circle"></i> {propObj.PropertyName}
            </span>
          </div>
          <div className="item-icon d-flex alig-items-center justify-content-between">
            <span
              className="item-money"
              style={{ color: "#00C89E", fontWeight: "bold" }}
            >
              <i className="fas fa-check-circle"></i> RM {propObj.Price}
            </span>
            <Link to={`/property/${propObj.PropertyId}`} className="item-title">
              <button className="btn btn-detail">View</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlatItem;
