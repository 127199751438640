import { Md360 } from "react-icons/md";
import { BsPinMap } from "react-icons/bs";
import { AiOutlinePropertySafety } from "react-icons/ai";

const Subscribe = () => {
  return (
    <section className="section-subscribe pt-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 mx-auto">
            <div className="banner-area text-center pt-4 pb-4">
              <p className="title">SERVICES</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 mx-auto">
            <div className="row">
              <div className="col-lg-2">
                <div className="sbs-icon"><Md360 className="fa-3x"/></div>
              </div>
              <div className="col-lg-10">
                <p className="sbs-description" >
                  360 Virtual Viewing
                </p>
                <p className="sbs-description-t">
                  Get a quick virtual viewing of rooms within 24 hours.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mx-auto">
            <div className="row">
              <div className="col-lg-2">
                <div className="sbs-icon"><BsPinMap className="fa-3x"/></div>
              </div>
              <div className="col-lg-10">
                <p className="sbs-description" >
                  Physical Viewing
                </p>
                <p className="sbs-description-t">
                  Offline viewing at the exact location.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mx-auto">
            <div className="row">
              <div className="col-lg-2">
                <div className="sbs-icon"><AiOutlinePropertySafety className="fa-3x"/></div>
              </div>
              <div className="col-lg-10">
                <p className="sbs-description" >
                  Property Management
                </p>
                <p className="sbs-description-t">
                  Handling the day-to-day operations of the real estate, from
                  screening tenants to arranging for repairs and maintenance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
