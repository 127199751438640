import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { getContract } from "../../api/contract/contract";
import { submitTransaction } from "../../api/transactions/transactions";
import { showToast } from "../../components/Toast";
import { DropzoneArea } from "material-ui-dropzone";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import firebase from "../../config/firebase";
import { Button } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
const accessTokenLabel = process.env.REACT_APP_USER_ID_LABEL;

const storage = getStorage(firebase);
const metadata = { contentType: "image/jpg" };
let imagePath;

export default function UploadTransactions(props) {
  const [clientId, setClientId] = useState(0);
  const [category, setCategory] = useState("");
  const [amount, _setAmount] = useState(0);
  const [imageFile, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const cookies = new Cookies();

  useEffect(() => {
    const id = cookies.get(accessTokenLabel);
    setClientId(Number(id));
  }, []);

  const deleteFromFirebase = (imagePath) => {
    let pictureRef = ref(storage, imagePath);
    deleteObject(pictureRef).then(() => {});
  };

  const styleSheet = {
    textField: { width: "60%", height: 50, paddingTop: "0.4%" },
    labelText: {
      textAlign: "end",
      verticalAlign: "top",
      fontSize: 18,
      color: "dimgrey",
      opacity: 0.8,
      paddingTop: "6%",
    },
    label: { width: "100%", height: 50, paddingRight: "3%" },
    infoRow: { flexDirection: "row", display: "flex", marginBottom: 50 },
  };

  async function submitForm() {
    const storageRef = ref(storage, "transactions/" + imagePath[0]?.name);
    const uploadTask = uploadBytesResumable(storageRef, imagePath[0], metadata);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        switch (error.code) {
          case "storage/unauthorized":
            break;
          case "storage/canceled":
            break;
          case "storage/unknown":
            break;
          default:
            break;
        }
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImage(downloadURL);
        });
      }
    );
  }

  function SubmitForm() {
    try {
      setIsLoading(true);
      if (imageFile.includes("undefined")) {
        throw new Error("Please upload at least one file.");
      } else if (clientId === 0) {
        throw new Error("Please select at least one tenant.");
      } else if (category === "") {
        throw new Error("Please select at least one category.");
      } else if (amount < 1) {
        throw new Error("Please enter the amount.");
      }
      submitTransaction({
        clientId: clientId,
        amount: amount,
        image: imageFile,
        Category: category,
      }).then((res) => {
        if (res.data.IsSuccess) {
          showToast("success", "New transaction is created successfully.");
          history.push("/contract");
        } else {
          throw new Error("Failed to create a transaction!");
        }
      });
    } catch (error) {
      showToast("error", `${error}`);
    } finally {
      setIsLoading(false);
    }
  }
  const history = useHistory();

  return (
    <React.Fragment>
      <Header />
      <div className="container">
        <Container component="main" maxWidth="sl" sx={{ mb: 4 }}>
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 4, md: 6 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Upload a New Transaction
            </Typography>
            <br />
            <Grid container spacing={3} mt={3}>
              <Grid item xs={12} sm={3}>
                <div style={styleSheet.label}>
                  <p style={styleSheet.labelText}>Category:</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  options={["Monthly Rental", "Utility Fee", "Others"]}
                  renderInput={(params) => (
                    <TextField {...params} label="Category" />
                  )}
                  onChange={(event, newValue) => {
                    setCategory(newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <div style={styleSheet.label}>
                  <p style={styleSheet.labelText}>Amount:</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Amount
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    defaultValue={0}
                    onChange={(e) => _setAmount(Number(e.target.value))}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    label="Amount"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div style={styleSheet.label}>
                  <p style={styleSheet.labelText}>Image:</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={9}>
                <label
                  htmlFor="contained-button-file"
                  style={{ width: "100%" }}
                >
                  <DropzoneArea
                    acceptedFiles={["image/jpg", "image/png", "image/jpeg"]}
                    filesLimit={1}
                    onChange={(e) => {
                      imagePath = e;
                      submitForm();
                    }}
                    onDelete={() => {
                      deleteFromFirebase(imageFile);
                    }}
                  />
                </label>
              </Grid>
            </Grid>
            <br />
            <Box style={{ justifyContent: "space-around", display: "flex" }}>
              <Button
                disabled={isLoading ? true : false}
                variant="contained"
                color="default"
                width={120}
                height={40}
                onClick={() => {
                  if (imageFile) {
                    deleteFromFirebase(imageFile);
                  }
                  history.push("/contract");
                }}
              >
                Back
              </Button>
              <Button
                disabled={isLoading ? true : false}
                variant="contained"
                color="primary"
                width={120}
                height={40}
                onClick={() => {
                  SubmitForm();
                }}
              >
                Submit
              </Button>
            </Box>
          </Paper>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}
