import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ImageBanner from "../../components/ImageBanner";

const AboutUs = () => {
    return (
        <section className="about">
            <Header />
            <ImageBanner imgUrl={'/img/Company/about-us.jpg'} text={'About Us'} />
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <img src="/img/Company/about-us2.jpg" alt="product" className="aboutus-img" />
                        </div>
                        <div className="col-lg-7">
                            <div className="about-item">
                                <div className="title">
                                    URBAN ROOM
                                </div>
                                <div className="title">
                                    PROPERTY MANAGEMENT
                                </div>
                                <div className="about-text">
                                    The idea for Urban Room came about at the beginning of year 2018, where we just started our property advisor journey in Bandar Sunway area. Along our advisory journey we found out the shocking truth about the outstations and overseas investors struggling to manage their properties. Urban Room take this as an opportunity by offering a solution to the market and that is where it all began with.
                                </div>
                                <div className="about-text">
                                    Along our journey we collaborated with one other Bandar Sunway Property Specialists and an Interior Designer, then form into a property management company.
                                </div>
                                <div className="about-text">
                                    We live in an increasingly competitive environment, and to be outstanding, unique, and affordable is KING and people want the world at their fingertips. And so the seeds of Urban Room were planted - from Upgrade the unit via:
                                </div>
                                <div className="about-features">
                                    <p className="about-feature"><i className="fas fa-long-arrow-alt-right"></i> Interior Design</p>
                                    <p className="about-feature" ><i className="fas fa-long-arrow-alt-right"></i> Rental Management</p>
                                    <p className="about-feature"><i className="fas fa-long-arrow-alt-right"></i> Property Management</p>
                                    <p className="about-feature"><i className="fas fa-long-arrow-alt-right"></i> Maintenance</p>
                                    <p className="about-feature"><i className="fas fa-long-arrow-alt-right"></i> Landlord Protection Plan</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    )
}

export default AboutUs