import Title from "../../components/Title"
import ServiceItem from "./ServiceItem"

const ServiceList = ({ setCurrentFlag }) => {
    const title = {
        text: "Our Services",
        description: ""
    }

    const services = [
        {
            name: "360 Virtual Viewing",
            imgUrl: "/img/Book/360.png",
            time: "15 min",
        },
        {
            name: "Physical Viewing",
            imgUrl: "/img/Book/physical.jpg",
            time: "15 min",
        },
        {
            name: "Property Management",
            imgUrl: "/img/Book/property-management.jpg",
            time: "15 min",
        }
    ]

    return (
        <section className="section-teams">
            <div className="container">
                <Title title={title.text} description={title.description} />
                <div className="row">
                    {
                        services.map((elem, index) => {
                            return (<ServiceItem key={index} service={elem} setCurrentFlag={setCurrentFlag} />)
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default ServiceList;