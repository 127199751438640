import CircularProgress from "@mui/material/CircularProgress";

export const showLoader = (isLoading) => {
  if (isLoading) {
    return (
      <div className="container" style={{ minHeight: 600 }}>
        <div
          style={{
            position: "absolute",
            margin: 100,
            height: 250,
            top: 350,
            left: "43%",
          }}
        >
          <CircularProgress />
        </div>
      </div>
    );
  }
};
