import axios from "axios";
import { retrieveAccessToken } from "../authUtil";

export const getContract = async () => {
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/contract/get`,
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`,
    },
  };
  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getContractById = async (id) => {
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/contract/details?id=${id}`,
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`,
    },
  };
  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const createContract = async (form) => {
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/contract/add`,
    data: form,
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`,
    },
  };
  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const editContract = async (form) => {
  const config = {
    method: "PUT",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/contract/edit`,
    data: form,
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`,
    },
  };
  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteContract = async (id) => {
  const config = {
    method: "DELETE",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/contract/delete?id=${id}`,
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`,
    },
  };
  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const downloadContractTemplate = (
  id,
  template = "URBAN_ROOM_AGREEMENT"
) => {
  // const config = {
  //   method: "GET",
  //   url: `${process.env.REACT_APP_API_BASE_URL}/api/contract/download?id=${id}`,
  //   headers: {
  //     Authorization: `Bearer ${retrieveAccessToken()}`,
  //   },
  // };
  // return axios(config)
  //   .then((res) => {
  //     return res;
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
  return ` ${
    process.env.REACT_APP_API_BASE_URL
  }/api/contract/download?id=${id}&template=${template}&token=${retrieveAccessToken()}`;
};
