import React from "react";
import Select from "react-select";

const PropertyFilterSelector = ({ value, onSelect, options, label }) => {
  return (
    <div className="col-lg-4 pb-3">
      <Select
        defaultValue={value}
        onChange={onSelect}
        options={options}
        placeholder={label}
      />
    </div>
  );
};

export default PropertyFilterSelector;
