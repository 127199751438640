import React from "react"
import { Toaster } from "./Toast"

const Footer = () => {
    return (
        <section className="footer">

            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <i className="fas fa-home"></i>
                        <span className="footer-other-text d-block mt-3 mb-3">
                            Urban Room Property Management
                        </span>
                        <div className="footer-social">
                            <div className="footer-social-item"><i className="fab fa-facebook" onClick={() => window.open('https://www.facebook.com/subangaccommodation.my')}></i></div>
                            <div className="footer-social-item"> <i className="fab fa-instagram" onClick={() => window.open('https://www.facebook.com/subangaccommodation.my')}></i></div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <p className="footer-title">Menu</p>
                        <ul className="footer-ul">
                            <li><a href="/#">Home</a></li>
                            <li><a href="/properties">Properties</a></li>
                            <li><a href="/aboutus">About</a></li>
                            <li><a href="/contact">Contact</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-10">
                        <p className="footer-title">Contact</p>
                        <ul className="footer-ul">
                            <li className="d-flex">
                                <div className="footer-info-item"><i className="fas fa-clock"></i></div> <span className="footer-text">10:00-19:00</span>
                            </li>
                            <li className="d-flex">
                                <div className="footer-info-item" ><i className="fas fa-envelope"></i></div> <a className="footer-text" href="mailto: admin@urbanroomy.com">admin@urbanroomy.com</a>
                            </li>
                            <li className="d-flex">
                                <div className="footer-info-item"><i className="fas fa-map-marker-alt"></i></div> <span className="footer-text"> 1st Floor, 6a, Jalan PJS 11/7, Bandar Sunway, 47500 Subang Jaya, Selangor </span>
                            </li>
                            <li className="d-flex">
                                <div className="footer-info-item"><i className="fas fa-phone-alt"></i></div> <span className="footer-text">60 17-268 8005</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <Toaster />
            </div>
        </section>
    )
}

export default Footer