import axios from "axios";
import { retrieveAccessToken } from "../authUtil";

export const addProperty = async (details) => {
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/property/add`,
    data: details,
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`,
    },
  };
  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

/// get Property
export const getProperty = async (id) => {
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/property/details?id=${id}`,
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`,
    },
  };

  return axios(config);
};

export const updateProperty = async (id, details) => {
  const config = {
    method: "PUT",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/property/edit`,
    data: details,
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`,
    },
  };
  return axios(config);
};

export const listProperties = async () => {
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/property/get`,
  };
  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const listPropertiesV2 = async ({ page, limit, filter }) => {
  if (!page) {
    page = 1;
  }
  if (!limit) {
    limit = 10;
  }

  let searchTerm = `?page=${page}&limit=${limit}`;

  if (filter) {
    searchTerm = searchTerm + `&filter=${filter}`;
  }

  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/property/v2/get${searchTerm}`,
  };
  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

/// get property details by using id
export const getPropertyById = async (id) => {
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/property/details?id=${id}`,
  };

  return axios(config);
};

export const updatePropertyStatus = async (id, statusCode) => {
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/property/UpdateStatus?id=${id}&statusCode=${statusCode}`,
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`,
    },
  };
  return axios(config);
};

export const editProperty = async () => {
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/property/edit`,
  };
  return axios(config);
};

export const deleteProperty = async (id) => {
  const config = {
    method: "DELETE",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/property/delete?id=${id}`,
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`,
    },
  };
  return axios(config);
};
