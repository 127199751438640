import React, { useEffect, useState } from "react";

const AreaItem = ({ area }) => {
    const [areaObj, setArea] = useState({});

    useEffect(() => {
        setArea(area);
    }, [area]);

    return (
        <div className="col-lg-4">
            <div className="team">
                <div className="team-img">
                    <img src={areaObj.imgUrl} alt={areaObj.name} />
                </div>
                <div className="team-info">
                    <h6 className="team-name">{areaObj.name}</h6>
                    <div className="social-links">
                        <div className="social-item" onClick={() => window.open(areaObj.location)}><i className="fa fa-map-marker-alt" ></i></div>
                        <div className="social-item" onClick={() => window.location.replace(areaObj.searchUrl)}><i className="fa fa-search"></i></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AreaItem