import Title from "./Title";
import AreaItem from "./AreaItem";

const AreaList = () => {
  const title = {
    text: "Available Properties",
    description: "",
  };

  const area = [
    {
      name: "Union Suites",
      imgUrl: "/img/AreaList/union-suite.jpeg",
      location: "https://goo.gl/maps/fv768wm6YjpW9XHt6",
      searchUrl: "/properties?category=Union Suites",
    },
    {
      name: "GeoLake Residence",
      imgUrl: "/img/AreaList/sunway-geolake.jpg",
      location: "https://goo.gl/maps/6p1L2acffdNNZj4N8",
      searchUrl: "/properties?category=Geolake Residence",
    },
    {
      name: "Nadayu28",
      imgUrl: "/img/AreaList/nadayu28.jpeg",
      location: "https://goo.gl/maps/guiwaA4gAANibWAH6",
      searchUrl: "/properties?category=Nadayu 28",
    },
    {
      name: "The Grand Subang SS13",
      imgUrl: "/img/AreaList/grand-ss13.jpeg",
      location: "https://goo.gl/maps/BP4ToHKMD5ecU9ez5",
      searchUrl: "/properties?category=TGS 13",
    },
    {
      name: "The Grand Subang SS15",
      imgUrl: "/img/AreaList/grand-ss15.jpg",
      location: "https://goo.gl/maps/dkkJmRrMMVJrmaHHA",
      searchUrl: "/properties?category=TGS 15",
    },
    {
      name: "Greenfield Residence",
      imgUrl: "/img/AreaList/greenfield.jpg",
      location: "https://goo.gl/maps/MCvyLJw8WLaCExhz6",
      searchUrl: "/properties?category=Greenfield Residence",
    },
  ];

  return (
    <section className="section-best-estate">
      <div className="container">
        <Title title={title.text} description={title.description} />
        <div className="row">
          {area.map((elem, index) => {
            return <AreaItem key={index} area={elem} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default AreaList;
