import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";
import moment from "moment";

const accessTokenLabel = process.env.REACT_APP_ACCESS_TOKEN_LABEL;
const userIdLabel = process.env.REACT_APP_USER_ID_LABEL;
const userTypeLabel = process.env.REACT_APP_USER_TYPE_LABEL;

const cookies = new Cookies();

export const retrieveAccessToken = () => {
  const access_token = cookies.get(accessTokenLabel);
  if (!access_token) {
    logOut();
    return;
  }
  const decodedToken = jwt_decode(access_token);
  const currentTime = moment().unix();
  const { exp } = decodedToken;
  if (moment(currentTime).isAfter(exp)) {
    logOut();
    return;
  }
  return access_token;
};

const logOut = () => {
  cookies.remove(accessTokenLabel);
  cookies.remove(userIdLabel);
  cookies.remove(userTypeLabel);
};
