import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import { useParams, useHistory } from "react-router-dom";
import {
  getPropertyById,
  listProperties,
  updatePropertyStatus,
} from "../api/property/property";
import { listConfigure } from "../api/configure/configure";
import { showToast } from "../components/Toast";
import { showLoader } from "../components/Loader";
import _ from "lodash";
import Header from "./Header";
import Footer from "./Footer";
import { Button } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import StatusConst from "../const/StatusConst";
import { dateTimeConverter } from "../components/helpers";

const PropertyDetails = () => {
  const history = useHistory();

  const { propertyId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [configures, setConfigures] = useState([]);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [properties, setProperties] = useState([]);
  const [imgArr, setImgArr] = useState([]);
  const [location, setLocation] = useState();
  const [statusCode, setStatusCode] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isShowBackDrop, setIsShowBackDrop] = useState(false);

  const onGetPropertyDetails = async () => {
    try {
      setIsLoading(true);

      const { data } = await getPropertyById(propertyId);
      const isSuccess = data?.IsSuccess || false;

      const { data: _data } = await listProperties();
      const _isSuccess = _data?.IsSuccess || false;

      const { data: configuresData } = await listConfigure("PropertyName");
      const configureSuccess = configuresData?.IsSuccess || false;

      if (!isSuccess || !_isSuccess || !configureSuccess) {
        throw new Error("Retrieve Property Failed!");
      }
      const _propertyDetails = data.Result;
      setLocation(_propertyDetails.PropertyName.replace(" ", "+"));
      setPropertyDetails(_propertyDetails);

      //set status code here
      setStatusCode(_propertyDetails.StatusCode);

      let _imagesArr = _propertyDetails.Image.split(",");
      _imagesArr = _imagesArr.map((image) => {
        return { original: image, thumbnail: image };
      });
      setImgArr(_imagesArr);

      //sort and set properties here
      let _properties = _data.Result;
      _properties = _.sortBy(_properties, ["CreatedDate"]);
      _properties = _properties.slice(0, 3);
      setProperties(_properties);

      //set configures here
      const _configures = configuresData.Result;
      setConfigures(
        _configures.map((_configure) => {
          return _configure.Value;
        })
      );
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onReserveProperty = async () => {
    try {
      setIsShowBackDrop(true);
      const { PropertyId } = propertyDetails;
      await updatePropertyStatus(PropertyId, StatusConst.LOCKED);
      setIsShowBackDrop(false);
      showToast("success", "Property successfully reserved!");
      setStatusCode(StatusConst.LOCKED);
    } catch (error) {
      setIsShowBackDrop(false);
      showToast("error", "Login first before reserve a room");
    } finally {
      setIsDialogOpen(false);
    }
  };

  const onCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const onOpenDialog = () => {
    setIsDialogOpen(true);
  };

  useEffect(() => {
    onGetPropertyDetails();
  }, [propertyId]);

  return (
    <div className="flat-detail">
      <Header />
      {isLoading ? (
        showLoader(isLoading)
      ) : (
        <>
          <div className="page-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h1 className="page-title">PROPERTY DETAILS</h1>
                  <h2 className="page-description">{propertyDetails.Name}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-5 mb-5">
            <div className="row">
              <div className="col-lg-12">
                <div className="fd-top flat-detail-content">
                  <div>
                    <h3 className="flat-detail-title">
                      {propertyDetails.PropertyName}
                    </h3>
                    <p className="fd-address">
                      <i className="fas fa-map-marker-alt"></i>
                      {propertyDetails.Address}
                    </p>
                    <p className="fd-price">RM {propertyDetails.Price}</p>
                  </div>
                </div>
                <ImageGallery
                  flickThreshold={0.5}
                  slideDuration={0}
                  items={imgArr}
                  showNav={false}
                  showFullscreenButton={false}
                  showPlayButton={false}
                />
                <div className="row">
                  <div className="col-lg-8">
                    <div className="fd-item">
                      <h4>Description</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: propertyDetails.Description?.split(/\n+/)
                            ?.map((e) => `<p>${e}</p>\n`)
                            .join(""),
                        }}
                      ></p>
                    </div>
                    <div className="fd-item fd-property-detail">
                      <h4>Details</h4>
                      {propertyDetails.AvailableFrom &&
                        propertyDetails.AvailableFrom !=
                          "0001-01-01T08:00:00" && (
                          <div className="row">
                            <div className="col-5">
                              <span>Available From</span>
                            </div>
                            <div className="col">
                              <span>
                                :&emsp;
                                {dateTimeConverter(
                                  propertyDetails.AvailableFrom
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                      <div className="row">
                        <div className="col-5">
                          <span>Block</span>
                        </div>
                        <div className="col">
                          <span>:&emsp;{propertyDetails.BlockNum}</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <span>Furnishing</span>
                        </div>
                        <div className="col">
                          <span>:&emsp;{propertyDetails.Furnishing}</span>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-5">
                          <span>Floor</span>
                        </div>
                        <div className="col">
                          <span>:&emsp;{propertyDetails.FloorNum}</span>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-5">
                          <span>Car Parks</span>
                        </div>
                        <div className="col">
                          <span>:&emsp;{propertyDetails.Carpark}</span>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-5">
                          <span>Unit</span>
                        </div>
                        <div className="col">
                          <span>:&emsp;{propertyDetails.UnitNum}</span>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-5">
                          <span>Square Feet</span>
                        </div>
                        <div className="col">
                          <span>:&emsp;{propertyDetails.Sqft}</span>
                        </div>
                      </div>
                    </div>
                    <div className="fd-item">
                      <h4>Maps</h4>
                      <iframe
                        src={
                          "https://www.google.com/maps/embed?origin=mfe&pb=!1m3!2m1!1s" +
                          location +
                          "!6i13"
                        }
                        title={"Google Map iframe"}
                        width="100%"
                        height="450"
                        loading="lazy"
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    {statusCode === StatusConst.AVAILABLE ? (
                      <div className="fd-sidebar-item">
                        <Button
                          style={styleSheet.button}
                          onClick={() =>
                            history.push({
                              pathname: "/book",
                              state: { catogory: "Physical Viewing" },
                            })
                          }
                        >
                          Book Appointment
                        </Button>
                      </div>
                    ) : (
                      <div className="fd-sidebar-item">
                        <Button
                          key={2}
                          style={{
                            ...styleSheet.button,
                            backgroundColor: "#D3D3D3",
                          }}
                          disabled
                        >
                          Book Appointment
                        </Button>
                      </div>
                    )}

                    <div className="fd-sidebar-item">
                      <h4>Recently Added</h4>
                      {properties.map((property) => {
                        const imgArr = property.Image.split(",");
                        const imgPath = imgArr[0];
                        return (
                          <div
                            className="recently-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              history.push(`/property/${property.PropertyId}`);
                            }}
                          >
                            <img src={imgPath} alt="detail" width="50px" />
                            <span>{property.Name}</span>
                          </div>
                        );
                      })}
                    </div>
                    <div className="fd-sidebar-item">
                      <h4>Category</h4>
                      <ul className="category-ul">
                        {configures.map((configure) => {
                          return (
                            <a href={`/properties?category=${configure}`}>
                              <li>{configure}</li>
                            </a>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Dialog
              onClose={() => {
                onCloseDialog();
              }}
              open={isDialogOpen}
              style={styleSheet.dialog}
            >
              <DialogTitle style={styleSheet.dialogTitle}>
                Property Reservation
              </DialogTitle>
              <p style={{ margin: 22, fontSize: 20, fontWeight: 500 }}>
                Are you sure to reserve this property?
              </p>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  style={{
                    ...styleSheet.actionButton,
                    backgroundColor: "white",
                    color: "#253156",
                    fontWeight: "bold",
                  }}
                  onClick={onCloseDialog}
                >
                  Cancel
                </Button>
                <Button
                  style={styleSheet.actionButton}
                  onClick={onReserveProperty}
                >
                  Confirm
                </Button>
              </div>
            </Dialog>
          </div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isShowBackDrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
      <Footer />
    </div>
  );
};

const styleSheet = {
  button: {
    height: 50,
    width: "100%",
    alignSelf: "center",
    fontSize: 20,
    fontFamily: "sans-serif",
    color: "white",
    backgroundColor: "#253156",
  },
  dialogTitle: {
    backgroundColor: "#253156",
    color: "white",
    borderRadius: 0,
  },
  actionButton: { margin: 20, color: "white", backgroundColor: "#253156" },
};

export default PropertyDetails;
