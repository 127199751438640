const Banner = () => {
  // const [search, setSearch] = useState();
  // const [find, setFind] = useState([]);
  // const [word, setWord] = useState("");
  // useEffect(() => {
  //     setSearch(["a", "b", "test", "mb"])
  // }, [])
  // const findSearch = (e) => {
  //     setWord(e.target.value)
  //     const filteredCountries = search.filter(item => item.indexOf(e.target.value) > -1 ? item : null);
  //     e.target.value.length === 0 ? setFind([]) : setFind(filteredCountries);
  // }
  // const findResult = () => {
  //     if (find.length === 0 && word.length > 0) {
  //         return <div className="find-search">Not Found</div>
  //     }
  //     if (find.length > 0) {
  //         return <div className="find-search">
  //             {
  //                 find.map(item => {
  //                     return <Link key={item} to="#">{item}</Link>
  //                 })
  //             }
  //         </div>
  //     }
  // }

  return (
    <div
      className="banner d-flex align-items-center"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/img/banner.jpg"})`,
      }}
    >
      <div className="bg-custom">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 mx-auto">
              <div className="banner-area text-center pt-4 pb-4">
                <h2 className="mt-2 mb-4 banner-title">
                  <strong> Urban Room</strong>{" "}
                </h2>
                <p>
                  We are young and energetic individuals who are former students
                  of Sunway, Monash and Taylor Universities, let's our team to
                  serve you now!
                </p>
                {/* <div className="search-area">
                                    <input value={word} onChange={(e) => findSearch(e)} type="text" className="inp-search" placeholder="Find your ideal room" />
                                    <button className="btn-search m-2">
                                        <span>Search </span>
                                        <i className="bi bi-search"></i>
                                    </button>
                                </div> */}
                {/* {findResult()} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
