import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { listConfigure } from "../../api/configure/configure";
import { addFeedback } from "../../api/feedback/feedback";
import { showToast } from "../../components/Toast";
import { DropzoneArea } from "material-ui-dropzone";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import firebase from "../../config/firebase";
import { Button } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ImageBanner from "../../components/ImageBanner";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Cookies from "universal-cookie";

const userIdLabel = process.env.REACT_APP_USER_ID_LABEL;
const storage = getStorage(firebase);
const metadata = { contentType: "image/jpg" };
let imagePath;

export default function Feedback(props) {
  const [userId, setUserId] = useState();
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [imageFile, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const cookies = new Cookies();

  useEffect(() => {
    const id = cookies.get(userIdLabel);
    setUserId(id); //set to 1000
    if (categories.length < 1) {
      onListConfigures();
    }
  }, [userId]);

  const onListConfigures = async () => {
    try {
      const { data } = await listConfigure("FeedbackCategory");
      const isSuccess = data?.IsSuccess || false;
      if (!isSuccess) {
        throw new Error(
          "Failed to retrieve feedback category. Please Try Again."
        );
      }
      const result = data?.Result;
      setCategories(
        result.map((res) => {
          return res.Value;
        })
      );
    } catch (error) {
      showToast("Error", error);
    }
  };
  const deleteFromFirebase = (imagePath) => {
    let pictureRef = ref(storage, imagePath);
    deleteObject(pictureRef).then(() => {});
  };

  const styleSheet = {
    textField: { width: "60%", height: 50, paddingTop: "0.4%" },
    labelText: {
      textAlign: "end",
      verticalAlign: "top",
      fontSize: 18,
      color: "dimgrey",
      opacity: 0.8,
      paddingTop: "6%",
    },
    label: { width: "100%", height: 50, paddingRight: "3%" },
    infoRow: { flexDirection: "row", display: "flex", marginBottom: 50 },
  };

  async function submitForm() {
    const storageRef = ref(storage, "feeback/" + imagePath[0]?.name);
    const uploadTask = uploadBytesResumable(storageRef, imagePath[0], metadata);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        switch (error.code) {
          case "storage/unauthorized":
            break;
          case "storage/canceled":
            break;
          case "storage/unknown":
            break;
          default:
            break;
        }
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImage(downloadURL);
        });
      }
    );
  }

  function SubmitForm() {
    try {
      setIsLoading(true);
      if (imageFile.includes("undefined")) {
        throw new Error("Please upload at least one file.");
      } else if (category === "") {
        throw new Error("Please select at least one category.");
      } else if (description === "") {
        throw new Error("Please enter the describe.");
      } else if (title === "") {
        throw new Error("Please enter the title.");
      }
      addFeedback({
        Title: title,
        Description: description,
        Image: imageFile,
        Image2: "",
        Category: category,
        CreatedBy: userId,
      }).then((res) => {
        if (res.data.IsSuccess) {
          showToast(
            "success",
            "Thank you for reaching out and providing us with valuable feedback.."
          );
          window.location.reload();
        } else {
          throw new Error("Failed to submit a feedback!");
        }
      });
    } catch (error) {
      showToast("error", `${error}`);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <React.Fragment>
      <Header />
      <ImageBanner imgUrl={"/img/feedback.jpg"} text={"Feedback"} />
      <div className="container">
        <Container component="main" maxWidth="sl" sx={{ mb: 4 }}>
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 4, md: 6 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              COMPLAINT / DEFECT FORM
            </Typography>
            <br />
            <Grid container spacing={3} mt={3}>
              <Grid item xs={12} sm={3}>
                <div style={styleSheet.label}>
                  <p style={styleSheet.labelText}>Category:</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  options={categories}
                  renderInput={(params) => (
                    <TextField {...params} label="Category" />
                  )}
                  onChange={(event, newValue) => {
                    setCategory(newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <div style={styleSheet.label}>
                  <p style={styleSheet.labelText}>Title:</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-multiline-static">
                    Title
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-multiline-static"
                    onChange={(e) => setTitle(e.target.value)}
                    label="Title"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div style={styleSheet.label}>
                  <p style={styleSheet.labelText}>Description:</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-multiline-static">
                    Description
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-multiline-static"
                    minRows={5}
                    maxRows={5}
                    onChange={(e) => setDescription(e.target.value)}
                    multiline={true}
                    label="Description"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <div style={styleSheet.label}>
                  <p style={styleSheet.labelText}>Image:</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={9}>
                <label
                  htmlFor="contained-button-file"
                  style={{ width: "100%" }}
                >
                  <DropzoneArea
                    acceptedFiles={["image/jpg", "image/png", "image/jpeg"]}
                    filesLimit={1}
                    onChange={(e) => {
                      imagePath = e;
                      submitForm();
                    }}
                    onDelete={() => {
                      deleteFromFirebase(imageFile);
                    }}
                  />
                </label>
              </Grid>
            </Grid>
            <br />
            <Box style={{ justifyContent: "space-around", display: "flex" }}>
              <Button
                disabled={isLoading ? true : false}
                variant="contained"
                color="default"
                width={120}
                height={40}
                onClick={() => {
                  if (imageFile) {
                    deleteFromFirebase(imageFile);
                  }
                  props.history.push("/contract");
                }}
              >
                Back
              </Button>
              <Button
                disabled={isLoading ? true : false}
                variant="contained"
                color="primary"
                width={120}
                height={40}
                onClick={() => {
                  SubmitForm();
                }}
              >
                Submit
              </Button>
            </Box>
          </Paper>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}
