import React from "react";
import _toast, { Toaster as ToasterComp } from "react-hot-toast";

export const showToast = (status, message) => {
  switch (status) {
    case "error":
      return _toast.error(message);
    case "success":
      return _toast.success(message);
    default:
      return _toast(message);
  }
};

export const Toaster = () => {
  return (
    <ToasterComp
      position="bottom-center"
      toastOptions={{
        success: {
          style: { background: "green", color: "white" },
          iconTheme: {
            primary: "white",
            secondary: "green",
          },
        },
        error: {
          style: { background: "#cc0000", color: "white" },
          iconTheme: {
            primary: "white",
            secondary: "#cc0000",
          },
        },
      }}
    />
  );
};
