import FlatList from "../../components/FlatList";
import Banner from "../../components/Banner";
import React from "react";
import TeamList from "../../components/TeamList";
import Subscribe from "../../components/Subscribe";
import AreaList from "../../components/AreaList";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Home = () => {
  return (
    <React.Fragment>
      <Header />
      <Banner />
      <FlatList />
      <AreaList />
      <Subscribe />
      <TeamList />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
