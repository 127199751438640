import Footer from "./Footer";
import Header from "./Header";
import ImageBanner from "./ImageBanner";
import Cookies from "universal-cookie";
import { addFeedback } from "../api/feedback/feedback";
import { showToast } from "./Toast";
import validator from "validator";

const userIdLabel = process.env.REACT_APP_USER_ID_LABEL;

const Contact = () => {
  const cookies = new Cookies();
  const userId = cookies.get(userIdLabel);

  const onSubmitForm = async () => {
    try {
      const surname = document.getElementById("userSurname").value;
      const phone = document.getElementById("userPhoneNumber").value;
      const subject = document.getElementById("userSubject").value;
      const message = document.getElementById("userMessage").value;

      if (!surname || !phone || !subject || !message) {
        throw new Error("Some fields are empty");
      }

      if (!validator.isMobilePhone(phone, "ms-MY", { strictMode: true })) {
        throw new Error("Contact Number is not valid.");
      }

      const feedback = {
        Category: "Contact Us",
        Title: `New contact from ${surname}`,
        Description: `Contact Number is ${phone}`,
        CreatedBy: userId ? userId : "Anonymous Client",
      };

      const res = await addFeedback(feedback);
      if (res) {
        const isSuccess = res["data"]?.IsSuccess;
        if (isSuccess) {
          showToast("success", "Appointment Created");
        } else {
          throw new Error("Failed to create appointment");
        }
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  return (
    <section className="contact">
      <Header />
      <ImageBanner imgUrl={"/img/contact-us.jpg"} text={"Contact Us"} />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4">
                  <div className="contact-item">
                    <i className="fas fa-envelope"></i>
                    <h5>Mail</h5>
                    <h6>admin@urbanroomy.com</h6>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="contact-item">
                    <i className="fas fa-map-marker-alt"></i>
                    <h5>Address</h5>
                    <h6 style={{ textAlign: "center" }}>
                      1st Floor, 6a, Jalan PJS 11/7, Bandar Sunway, 47500 Subang
                      Jaya, Selangor
                    </h6>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="contact-item">
                    <i className="fas fa-phone-alt"></i>
                    <h5>Phone</h5>
                    <h6>60 17-268 8005</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row mt-5">
                <div className="col-lg-6">
                  <label>Name Surname</label>
                  <input
                    id="userSurname"
                    type="text"
                    className="inp-contact"
                    placeholder="Your Surname"
                  />
                </div>
                <div className="col-lg-6">
                  <label>Phone (+60)</label>
                  <input
                    id="userPhoneNumber"
                    type="text"
                    className="inp-contact"
                    placeholder="Your Phone Number (+60)"
                  />
                </div>
                <div className="col-lg-12">
                  <label>Subject</label>
                  <input
                    id="userSubject"
                    type="text"
                    className="inp-contact"
                    placeholder="Main Subject"
                  />
                </div>
                <div className="col-lg-12">
                  <label>Message</label>
                  <textarea
                    id="userMessage"
                    type="text"
                    className="ta-contact"
                    rows="4"
                    placeholder="Tell Us More"
                  ></textarea>
                </div>
                <div className="col-lg-12">
                  <button className="btn-contact" onClick={onSubmitForm}>
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Contact;
