import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@mui/material/Button";
import { showToast, Toaster } from "../../components/Toast";
import validator from "validator";
import { adminLogin } from "../../api/auth/auth";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import "./Login.css";

const accessTokenLabel = process.env.REACT_APP_ACCESS_TOKEN_LABEL;
const userIdLabel = process.env.REACT_APP_USER_ID_LABEL;
const userTypeLabel = process.env.REACT_APP_USER_TYPE_LABEL;

const cookies = new Cookies();

const Login = () => {
  const history = useHistory();

  const [userEmail, setUserEmail] = useState();
  const [userPass, setUserPass] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async () => {
    try {
      let credential = {};
      setIsLoading(true);
      if (!userEmail || !userPass) {
        throw new Error("Please Enter Username / Email or Password!");
      }
      const isEmail = validator.isEmail(userEmail);
      if (isEmail) {
        credential["email"] = userEmail;
      } else {
        credential["username"] = userEmail;
      }
      credential["password"] = userPass;

      const { data } = await adminLogin(credential);
      const { access_token, expires_in, user_id, user_type } = data;

      cookies.set(accessTokenLabel, access_token, {
        path: "/",
        maxAge: expires_in,
      });

      cookies.set(userIdLabel, user_id, {
        path: "/",
        maxAge: expires_in,
      });

      cookies.set(userTypeLabel, user_type, {
        path: "/",
        maxAge: expires_in,
      });

      if (data?.user_type !== "Client") {
        throw new Error("Wrong Username / Email or Password! Please Try Again");
      }
      showToast("success", "Successfully Login!");
      setTimeout(() => {
        history.push("/");
        window.location.reload();
        setIsLoading(false);
      }, 1500);
    } catch (error) {
      setIsLoading(false);
      showToast("error", error.message);
    }
  };

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text="Loading..."
      fadeSpeed={1000}
    >
      <div className="login-container">
        <div className="login-layer">
          <div className="login-form">
            <img
              className="login-img-logo"
              alt={"Urban Room Logo"}
              src={"/img/Logo/urban-room-logo2.jpeg"}
            />
            <img
              className="login-img"
              src={"/img/Login/login_img.jpg"}
              alt={"login_img.jpg"}
            />
            <TextField
              className="login-input-style"
              id="filled-basic"
              label="Username or Email"
              variant="standard"
              onChange={(e) => {
                setUserEmail(e.target.value);
              }}
            />
            <TextField
              className="login-input-style"
              id="outlined-basic"
              label="Password"
              variant="standard"
              type={showPassword ? "text" : "password"}
              onChange={(e) => {
                setUserPass(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      style={{ outline: "none" }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              className="login-button"
              style={{
                backgroundColor: "#ff5722",
                marginTop: "30px",
              }}
              onClick={() => {
                onSubmit();
              }}
            >
              Login
            </Button>
            <Button
              className="login-button"
              variant="text"
              style={{ color: "#ff5722", fontSize: 16 }}
              onClick={() => {
                history.push("/");
              }}
            >
              Back
            </Button>
          </div>
        </div>
        <Toaster />
      </div>
    </LoadingOverlay>
  );
};

export default Login;

// const styleSheet = {
//   container: {
//     width: "100vw",
//     height: "100vh",
//     backgroundPosition: "center",
//     backgroundSize: "cover",
//     backgroundRepeat: "no-repeat",
//     backgroundImage: `url(${
//       process.env.PUBLIC_URL + "/img/Login/client_background.jpg"
//     })`,
//   },
//   layer: {
//     backgroundColor: "rgba(0,0,0,0.5)",
//     width: "100vw",
//     height: "100vh",
//     flex: 1,
//     display: "flex",
//     justifyContent: "center",
//     alignSelf: "center",
//   },
//   form: {
//     backgroundColor: "white",
//     width: "30vw",
//     height: "80vh",
//     opacity: 0.8,
//     display: "flex",
//     flexDirection: "column",
//     alignSelf: "center",
//     borderRadius: "20px",
//   },
//   inputStyle: {
//     width: "70%",
//     alignSelf: "center",
//     backgroundColor: "white",
//     marginTop: 20,
//   },
//   icon: {
//     color: "#ff5722",
//     fontSize: 200,
//     alignSelf: "center",
//   },
//   button: {
//     width: "70%",
//     alignSelf: "center",
//     height: 50,
//     marginTop: 20,
//     fontSize: 16,
//     fontWeight: "bold",
//   },
// };
