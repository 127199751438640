import axios from "axios";

export const addFeedback = async (details) => {
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/feedback/add`,
    data: details,
  };
  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
