import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BedIcon from "@mui/icons-material/Bed";
import { useHistory } from "react-router-dom";

const BlogItem = ({
  price,
  propertyName,
  city,
  address,
  sqft,
  carParks,
  furnishing,
  imgPath,
  propertyId,
  statusCode,
  name,
}) => {
  function AddressFormatter(add) {
    return add.substring(0, 45);
  }

  function GetStatusByCode(code) {
    const status_code = {
      1001: "For Rent",
      1002: "Unavailable",
      1003: "Unavailable",
      1004: "Unavailable",
      1005: "Unavailable",
      1006: "Unavailable",
      1007: "Unavailable",
      1008: "Rent Out",
      1009: "Coming Soon",
      1010: "Coming Soon",
    };
    return status_code[code];
  }

  function GetStyleByCode(code) {
    if (GetStatusByCode(code) === "For Rent") {
      return "bg-green";
    } else if (GetStatusByCode(code) === "Rent Out") {
      return "bg-red";
    } else {
      return "bg-yellow";
    }
  }

  const history = useHistory();
  return (
    <div className="col-lg-4">
      <div className="blog-item">
        <div className="best-estate">
          <div className="best-estate-item">
            <div className="best-estate-img-area">
              <img className="best-estate-img" src={imgPath} alt="flat" />
              <div
                className={`best-estate-state ${GetStyleByCode(statusCode)}`}
              >
                {GetStatusByCode(statusCode)}
              </div>
            </div>
          </div>
        </div>
        <div className="blog-content">
          <h6 style={{ marginBottom: 20 }}>
            <big style={{ color: "rgb(0, 200, 158)" }}>RM {price}</big> /month
          </h6>
          <h4 className="blog-title">
            {name} ({propertyName})
          </h4>
          <div className="blog-info" style={{ fontSize: 13 }}>
            <LocationOnIcon
              style={{ color: "rgb(0, 200, 158)" }}
              fontSize="small"
            />
            <span>{AddressFormatter(address)}</span>
          </div>
          <div style={{ paddingTop: 20 }}>
            <div className="blog-info">
              <div className="blog-info-item">
                <span>{sqft}</span>&ensp;Sqft
              </div>
              &emsp;&emsp;
              <div className="blog-info-item">
                <DirectionsCarFilledOutlinedIcon
                  style={{ marginTop: -5 }}
                  fontSize="small"
                />
                &ensp;
                <span>{carParks}</span>
              </div>
              &emsp;&emsp;&emsp;
              <div className="blog-info-item">
                <BedIcon style={{ marginTop: -5 }} fontSize="small" />
                &ensp;
                <span>{furnishing === "Fully Furnished" ? "Yes" : "No"}</span>
              </div>
            </div>
          </div>
          <div
            className="blog-info-item"
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="btn"
              style={{
                backgroundColor: "#364465",
                color: "white",
              }}
              onClick={() => {
                history.push(`/property/${propertyId}`);
              }}
            >
              View Details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogItem;
