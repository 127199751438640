import React, { useEffect, useState } from "react";
import Title from "./Title";
import FlatItem from "./FlatItem";
import { listProperties } from "../api/property/property";

const FlatList = () => {
  const [properties, setProperties] = useState([]);

  const title = {
    text: "View Rooms Virtually with Our Team",
    description:
      "At Urban Room Property Management, we take great pride in providing a high-level of personalized services to property investor in the Bandar Sunway area. That’s why we’ve carved out the reputation of being the best possible Property Management Company since 2019.",
  };

  useEffect(() => {
    let isMounted = true;

    const onGetProperties = async () => {
      try {
        const { data } = await listProperties();
        const isSuccess = data?.IsSuccess || false;
        if (isSuccess === false) {
          throw new Error("Retrieve Properties Error!");
        }
        if (data.Result.length < 3) {
          for (let i = data.Result.length; i < 3; i++) {
            data.Result.push({
              Name: "Coming Soon",
              PropertyName: "Coming Soon",
              Price: 0,
              PropertyId: "#",
              Image: "/img/product1.jpeg",
            });
          }
        }
        if (isMounted) {
          setProperties(data.Result);
        }
      } catch (error) {
        console.log("Error", `${error}`);
      }
    };

    onGetProperties();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <section className="section-all-re">
      <div className="container">
        <Title title={title.text} description={title.description} />
        <div className="row">
          {properties.map((elem, index) => {
            if (index < 3) {
              return <FlatItem key={index} property={elem} />;
            }
            return null;
          })}
        </div>
      </div>
    </section>
  );
};

export default FlatList;
