const StatusConst = {
    AVAILABLE: 1001,
    LOCKED: 1002,
    AGREEMENT_PAID: 1003,
    CONTRACT_CREATED: 1004,
    DEPOSIT_APPROVED: 1005,
    TENANT_CREATED: 1006,
    ASSIGN_CONTRACT: 1007,
    NOT_AVAILABLE: 1008,
    AVAILABLE_SOON: 1009,
    REJECTED: 1010,
}

export default StatusConst;