import "./App.css";
import React, { createContext, useState, useEffect } from "react";
import PropertyDetails from "./components/PropertyDetail";
import Home from "./pages/Home/Home";
import Book from "./pages/Book/Book";
import Contact from "./components/Contact";
import AboutUs from "./pages/Company/AboutUs";
import Team from "./pages/Company/Team";
import MyContract from "./pages/Profile/MyContract";
import Properties from "./pages/Properties/Properties";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import UploadTransactions from "./pages/Profile/UploadTransactions";
import Feedback from "./pages/Profile/Feedback";
import Login from "./pages/Auth/Login";
import { retrieveAccessToken } from "./api/authUtil";

const accessTokenLabel = process.env.REACT_APP_ACCESS_TOKEN_LABEL;

function App() {
  const authContext = createContext();
  const [accessToken, setAccessToken] = useState();
  function ProvideAuth({ children }) {
    const auth = retrieveAccessToken();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
  }

  function PrivateRoute({ children, ...rest }) {
    const auth = retrieveAccessToken();
    return (
      <Route
        {...rest}
        render={({ location }) =>
          auth ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }

  useEffect(() => {
    const _accessToken = retrieveAccessToken();
    setAccessToken(_accessToken);
  }, []);

  return (
    <ProvideAuth>
      <Router>
        <div className="App">
          <Route path="/" exact component={Home}></Route>
          <Route path="/book" component={Book}></Route>
          <Route path="/contact" component={Contact}></Route>
          <Route path="/aboutus" component={AboutUs}></Route>
          <Route path="/team" component={Team}></Route>
          <Route
            path="/property/:propertyId"
            component={PropertyDetails}
          ></Route>
          <Route path="/properties" component={Properties}></Route>
          <Route path="/login" component={Login}></Route>

          {/* authorised route */}
          <PrivateRoute path="/contract">
            <MyContract />
          </PrivateRoute>
          <PrivateRoute path="/transactions/upload">
            <UploadTransactions />
          </PrivateRoute>
          <PrivateRoute path="/feedback">
            <Feedback />
          </PrivateRoute>
        </div>
      </Router>
    </ProvideAuth>
  );
}

export default App;
