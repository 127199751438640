import React, { useEffect, useState } from "react";

const ServiceItem = ({ index, service, setCurrentFlag }) => {
    const [obj, setService] = useState({});

    useEffect(() => {
        setService(service);
    }, [service]);

    return (
        <div className="col-lg-4">
            <div className="team">
                <div className="team-img">
                    <img src={obj.imgUrl} alt={obj.name} />
                </div>
                <div className="team-info" onClick={() => {
                    setCurrentFlag(obj.name)
                }}>
                    <h5 className="team-name">{obj.name}</h5>
                    <h6 className="team-position">{obj.time}</h6>
                </div>
            </div>
        </div >
    )
}

export default ServiceItem