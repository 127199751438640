import Title from "./Title"
import TeamItem from "./TeamItem"

const TeamList = () => {
    const title = {
        text: "Urban Room's Team",
        description: "Delivering Peace of Mind on Property Investment"
    }

    const team = [
        {
            name: "Albert Ting",
            imgUrl: "/img/Company/founder.jpg",
            role: "Founder",
            facebook: "#",
            insta: "#",
            whatsapp: "#",
        },
        {
            name: "Jane Lee",
            imgUrl: "/img/Company/co-founder1.jpg",
            role: "Co-Founder 1",
            facebook: "#",
            insta: "#",
            whatsapp: "#",
        },
        {
            name: "Vincent Yong",
            imgUrl: "/img/Company/co-founder2.jpg",
            role: "Co-Founder 2",
            facebook: "#",
            insta: "#",
            whatsapp: "#",
        }
    ]

    return (
        <section className="section-teams">
            <div className="container">
                <Title title={title.text} description={title.description} />
                <div className="row">
                    {
                        team.map((elem, index) => {
                            return (<TeamItem key={index} team={elem} />)
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default TeamList;