import axios from "axios";
import { retrieveAccessToken } from "../authUtil";

export const getTransactions = async () => {
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/transaction/get`,
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`,
    },
  };
  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getTransactionDetails = async (id) => {
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/transaction/get?id=${id}`,
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`,
    },
  };
  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const approveTransaction = async (id) => {
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/transaction/issue?id${id}`,
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`,
    },
  };
  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const submitTransaction = async (transaction) => {
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/transaction/add`,
    data: transaction,
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`,
    },
  };
  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};
