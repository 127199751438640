import axios from "axios";

export const adminLogin = (credential) => {
  const { username, email, password } = credential;

  let input;
  if (username) {
    input = `username=${username}&password=${password}`;
  } else {
    input = `email=${email}&password=${password}`;
  }

  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/auth/token?${input}`,
  };

  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw new Error("Wrong Username / Email or Password! Please Try Again");
    });
};

export const forgotPassword = (credential) => {
  const { username, email } = credential;

  let input;
  if (username) {
    input = `username=${username}`;
  } else {
    input = `email=${email}`;
  }

  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URL}/api/auth/forgotpassword?${input}`,
  };

  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};
