import ServiceList from "./ServiceList";
import React, { useEffect, useState } from "react";
import BookForm from "./BookForm";
import ImageBanner from "../../components/ImageBanner";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import PropTypes from "prop-types";

const Book = () => {
  const [currentFlag, setCurrentFlag] = useState("");
  //const { catogory } = props.location.state;

  useEffect(() => {
    // if (catogory !== "") {
    //   setCurrentFlag(catogory);
    // }

    if (currentFlag !== "") {
      window.scrollTo(0, document.body.scrollHeight - 1000);
    }
  }, [currentFlag]);

  return (
    <React.Fragment>
      <Header />
      <ImageBanner
        imgUrl={"/img/Book/appointment.jpg"}
        text={"Book Appointment Now"}
      />
      <ServiceList setCurrentFlag={setCurrentFlag} />
      {currentFlag !== "" && (
        <BookForm currentFlag={currentFlag} setCurrentFlag={setCurrentFlag} />
      )}
      <Footer />
    </React.Fragment>
  );
};

Book.defautProps = {
  catogory: "",
};

Book.propTypes = {
  catogory: PropTypes.string,
};

export default Book;
